import React, { memo, useState, useEffect, useDeferredValue } from 'react';
import { api } from '../../../api/api';
//hooks
import { useQuery } from 'react-query';
//style
import 'react-best-tabs/dist/index.css';
import { TableWrapper } from './style';
//components
import { Form, Spinner, Dropdown, Button } from 'react-bootstrap';
import Card from '../../../components/card/Card';
import {
	Table,
	Header,
	HeaderRow,
	Body,
	Row,
	HeaderCell,
	Cell,
} from '@table-library/react-table-library/table';
import Chart from 'react-apexcharts';
import lineChartData from '../../../utils/charts/lineChartData';
// AOS
import AOS from 'aos';
import 'aos';
import '../../../../node_modules/aos/dist/aos.css';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
//pseudotypes
import { productTypes } from '../../../utils/types/productTypes';
//hooks
import { useSessionStorage } from '@uidotdev/usehooks';
import { useSort } from '@table-library/react-table-library/sort';
import { usePagination } from '@table-library/react-table-library/pagination';
import { useTheme } from '@table-library/react-table-library/theme';
import OSDetails from '../../../components/osDetails/osDetails';
import Searchbox from '../../../components/searchbox/searchbox';
import { Pagination } from '../../../components/table/pagination';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { DevStatusTable } from '../../../components/devStatusTable/devStatusTable';
import { settingSlice } from '../../../store/setting/reducers';

const DevsOverview = memo((props) => {
	const [isLoading, setLoading] = useSessionStorage('isLoading', false);
	const [devsOS, setDevsOS] = useSessionStorage('devsOS', []);
	const [devStatus, setDevStatus] = useSessionStorage('devStatus', []);

	const [limitTable, setLimitTable] = useState(5);
	const [activeTab, setActiveTab] = useState(1);
	const [showDevDetails, setShowDevDetails] = useState(false);

	const [users, setUsers] = useState();
	const [dev, setDev] = useState();
	const [tableData, setTableData] = useState();
	const [dataTable, setDataTable] = useState();
	const deferredDataTable = useDeferredValue(dataTable);
	const [totalYears, setTotalYears] = useState([]);
	const [yearFilter, setYearFilter] = useState('2024');
	const [productFilter, setProductFilter] = useState('');

	//Pega os dados e armazena no sessionstorage
	const {
		data: getDevsOS,
		refetch: refetchDevsOS,
		isFetching: isFetchingDevsOS,
		isRefetching: isRefetchingDevsOS,
	} = useQuery(
		['devsOS'],
		async () => {
			setLoading(true);
			const response = await api(
				`/devs/os/quantity?${productFilter !== '' ? `product=${productFilter}` : ''}`
			);
			setDevsOS(response?.data);

			return response?.data;
		},
		{
			onError: (err) => console.log(err),
			onSettled: () => setLoading(false),
			onSuccess: () => organizeData(),
		}
	);

	const {
		data: getDevStatus,
		refetch: refetchDevStatus,
		isFetching: isFetchingDevStatus,
		isRefetching: isRefetchingDevStatus
	} = useQuery(['devStatus'], async () => {
		const response = await api(`/devs/os/status?${productFilter !== '' ? `product=${productFilter}` : ''}`);
		const data = response?.data;
		setDevStatus(data);
		return response?.data;
	},
	{
		onError: (err) => console.log(err)
	}
);

	const getUser = (dev) => {
		setDev(dev);
		setShowDevDetails(true);
	};

	const organizeData = () => {
		const totals = {};

		let years = [new Date().getFullYear()];
		devsOS?.forEach((dev) => {
			const name = dev.name.trim(); // Remover espaços extras

			if (!totals[name]) {
				totals[name] = {
					name,
					qtdOS: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
					qtdHours: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
					totalOSYear: 0,
					totalHoursYear: 0,
					id: 0,
				};
			}

			dev.osQuantity?.forEach((data) => {
				const date = new Date(data.date);

				if (!years.includes(date.getFullYear())) years.push(date.getFullYear());

				if (yearFilter == date.getFullYear() || yearFilter === 'Todos') {
					totals[name].qtdOS[date.getMonth()] += Number(data.quantity);
					totals[name].totalOSYear += Number(data.quantity);
				}
				//Caso o dev não tenha horas ou OS, o id será zero
				totals[name].id = dev.id;
			});
			dev.osTime?.forEach((data) => {
				const date = new Date(data.date);

				if (yearFilter == date.getFullYear() || yearFilter === 'Todos') {
					var sum = totals[name].qtdHours[date.getMonth()];

					totals[name].qtdHours[date.getMonth()] = Number(data.time);
					totals[name].totalHoursYear += Number(data.time);
					//decimal de duas casas
					totals[name].qtdHours[date.getMonth()] = parseFloat(Number(data.time).toFixed(2));
				}
			});

			if (totalYears.length < 2) setTotalYears(years.sort((a, b) => a - b));

			var num = totals[name].totalHoursYear;
			totals[name].totalHoursYear = parseFloat(num.toFixed(2));

			if (
				activeTab === 1 &&
				JSON.stringify(totals[name].qtdOS) === JSON.stringify([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
			) {
				delete totals[name];
			}
			if (
				activeTab === 2 &&
				JSON.stringify(totals[name].qtdHours) ===
					JSON.stringify([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
			) {
				delete totals[name];
			}
		});

		setUsers(Object.values(totals));
		if (activeTab != 3) {
			setTableData({ nodes: Object.values(totals) });
		}
	};

	const changeActiveTab = (newActiveTab) => {
		if (newActiveTab == 3) {
			setTableData({nodes: devStatus});
		} else {
			organizeData();
			// setDataTable({nodes: users});
		}
		setActiveTab(newActiveTab);
	}

	//anim
	useEffect(() => {
		AOS.init({
			startEvent: 'DOMContentLoaded',
			disable: function () {
				var maxWidth = 996;
				return window.innerWidth < maxWidth;
			},
			throttleDelay: 10,
			once: true,
			duration: 700,
			offset: 10,
		});
	});

	useEffect(() => {
		refetchDevsOS();
		refetchDevStatus();
	}, [yearFilter, productFilter]);

	const setList = (data) => {
		if (activeTab == 3) {
			setTableData({nodes: data});
		} else {
			setTableData(data)
		}
	}

	return (
		<>
			<Card>
				<Card.Body>
					<div
						className='header-title d-flex justify-content-between align-items-center'
						style={{ width: '100%' }}
					>
						<h4 className='card-title'>Desenvolvedores</h4>
						<Searchbox list={activeTab == 3 ? devStatus : users} setResult={setTableData} atributeToSearch={'name'} />
					</div>

					<div className='d-flex justify-content-between' style={{ marginTop: '1rem' }}>
						<div className='d-flex'>
							<Dropdown>
								<b>Visualizar: </b>
								<Dropdown.Toggle
									as={Button}
									variant='text-gray'
									type='button'
									id='dropdownMenuButtonSM'
								>
									{(
										() => {
											switch (activeTab) {
												case 1:
													return "OS's";
												case 2:
													return "Horas";
												case 3:
													return "Status";
											}
										}
									)()}
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={() => changeActiveTab(1)}>OS's</Dropdown.Item>
									<Dropdown.Item onClick={() => changeActiveTab(2)}>Horas</Dropdown.Item>
									<Dropdown.Item onClick={() => changeActiveTab(3)}>Status</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
							<Dropdown>
								<b>Produto: </b>
								<Dropdown.Toggle
									as={Button}
									variant='text-gray'
									type='button'
									id='dropdownMenuButtonSM'
								>
									{productTypes[productFilter] || 'Todos'}
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={() => setProductFilter('')}>Todos</Dropdown.Item>
									{Object.keys(productTypes).map((productKey, index) => (
										<Dropdown.Item key={index} onClick={() => setProductFilter(productKey)}>
											{productTypes[productKey]}
										</Dropdown.Item>
									))}
								</Dropdown.Menu>
							</Dropdown>
							{activeTab != 3 ? 
						<Dropdown>
								<b>Período: </b>
								<Dropdown.Toggle
									as={Button}
									variant='text-gray'
									type='button'
									id='dropdownMenuButtonSM'
								>
									{yearFilter}
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={() => setYearFilter('Todos')}>Todos</Dropdown.Item>
									{totalYears.map((year, index) => (
										<Dropdown.Item key={index} onClick={() => setYearFilter(`${year}`)}>
											{year}
										</Dropdown.Item>
									))}
								</Dropdown.Menu>
							</Dropdown>
							:
							null	
						}
							
						</div>

						<div className='d-flex align-items-center' style={{ gap: '1rem' }}>
							<Form.Label htmlFor='shows'>Mostrar:</Form.Label>
							<Form.Select
								id='shows'
								style={{ width: 'max-content' }}
								onChange={(e) => setLimitTable(e.target.value)}
							>
								<option value='5'>5</option>
								<option value='25'>25</option>
								<option value='50'>50</option>
								<option value='100'>100</option>
							</Form.Select>
						</div>
					</div>
				</Card.Body>
			</Card>

			<div className='overflow-hidden d-slider1 ' data-aos='fade-up' data-aos-delay='800'>
				<Card>
					<Card.Body>
						{tableData?.nodes.length === 0 &&
							(!isFetchingDevsOS || !isRefetchingDevsOS) && (
								<div className='mb-4 mt-4 text-center'>Não há dados</div>
							)}
						{(isFetchingDevsOS || isRefetchingDevsOS) && (
							<div
								className='d-flex justify-content-center align-items-center'
								style={{ height: '200px' }}
							>
								<Spinner />
							</div>
						)}
						{tableData?.nodes.length > 0 && !isFetchingDevsOS && !isRefetchingDevsOS && !isFetchingDevStatus && !isRefetchingDevStatus?
							(
								() => {
									switch (activeTab) {
										case 1:
											return <DataTableOS
												deferredDataTable={tableData}
												totalYearLabel='totalOSYear'
												quantityLabel='qtdOS'
												getUser={getUser}
												limitTable={limitTable}
											/>;
										case 2:
											return <DataTableOS
												deferredDataTable={tableData}
												totalYearLabel='totalHoursYear'
												quantityLabel='qtdHours'
												getUser={getUser}
												limitTable={limitTable}
											/>;
										case 3:
											return <DevStatusTable data={tableData} limit={limitTable} getUser={getUser}/>;
									}
								}
							)() : null}
					</Card.Body>
				</Card>
			</div>

			<OSDetails
				show={showDevDetails}
				dev={dev}
				setShowDevDetails={setShowDevDetails}
				totalYears={totalYears}
				year={yearFilter}
			/>
		</>
	);
});

const DataTableOS = ({ deferredDataTable, totalYearLabel, quantityLabel, getUser, limitTable }) => {
	const [idsShowChart, setIdsShowChart] = useState([]);

	const handleSetIdsChart = (row) => {
		if (idsShowChart.includes(row)) {
			setIdsShowChart(idsShowChart.filter((id) => id !== row));
		} else {
			setIdsShowChart(idsShowChart.concat(row));
		}
	};

	const pagination = usePagination(deferredDataTable, {
		state: {
			page: 0,
			size: limitTable,
		},
		// onChange: onPaginationChange,
	});
	const theme = useTheme({
		BaseCell: `
			&:nth-of-type(1) {
				left: 0px;
			}`,
	});
	const sort = useSort(
		deferredDataTable,
		{
			state: {
				sortKey: 'name',
				reverse: false,
			},
		},
		{
			sortIcon: {
				iconDefault: null,
				iconUp: null,
				iconDown: null,
			},
			sortFns: {
				name: (array) => array.sort((a, b) => a.name.localeCompare(b.name)),
				jan: (array) => array.sort((a, b) => a[quantityLabel] - b[quantityLabel]),
				fev: (array) => array.sort((a, b) => a[quantityLabel] - b[quantityLabel]),
				mar: (array) => array.sort((a, b) => a[quantityLabel] - b[quantityLabel]),
				abr: (array) => array.sort((a, b) => a[quantityLabel] - b[quantityLabel]),
				mai: (array) => array.sort((a, b) => a[quantityLabel] - b[quantityLabel]),
				jun: (array) => array.sort((a, b) => a[quantityLabel] - b[quantityLabel]),
				jul: (array) => array.sort((a, b) => a[quantityLabel] - b[quantityLabel]),
				ago: (array) => array.sort((a, b) => a[quantityLabel] - b[quantityLabel]),
				set: (array) => array.sort((a, b) => a[quantityLabel] - b[quantityLabel]),
				out: (array) => array.sort((a, b) => a[quantityLabel] - b[quantityLabel]),
				nov: (array) => array.sort((a, b) => a[quantityLabel] - b[quantityLabel]),
				dez: (array) => array.sort((a, b) => a[quantityLabel] - b[quantityLabel]),
				total: (array) => array.sort((a, b) => a[quantityLabel] - b[quantityLabel]),
			},
		}
	);

	return (
		<>
			{deferredDataTable ? (
				<div className='table-responsive'>
					<TableWrapper>
						<Table
							id='table-devs-os'
							data={deferredDataTable}
							pagination={pagination}
							sort={sort}
							theme={theme}
							className='table'
							role='grid'
							data-toggle='data-table'
							style={{ width: '100vw' }}
						>
							{(tableList) => (
								<>
									<Header>
										<HeaderRow>
											<HeaderCell pinLeft sortKey='name'>
												Nome
											</HeaderCell>
											<HeaderCell sortKey='jan'>Jan</HeaderCell>
											<HeaderCell sortKey='fev'>Fev</HeaderCell>
											<HeaderCell sortKey='mar'>Mar</HeaderCell>
											<HeaderCell sortKey='abr'>Abr</HeaderCell>
											<HeaderCell sortKey='mai'>Mai</HeaderCell>
											<HeaderCell sortKey='jun'>Jun</HeaderCell>
											<HeaderCell sortKey='jul'>Jul</HeaderCell>
											<HeaderCell sortKey='ago'>Ago</HeaderCell>
											<HeaderCell sortKey='set'>Set</HeaderCell>
											<HeaderCell sortKey='out'>Out</HeaderCell>
											<HeaderCell sortKey='nov'>Nov</HeaderCell>
											<HeaderCell sortKey='dez'>Dez</HeaderCell>
											<HeaderCell sortKey='total' style={{ textAlign: 'left', marginLeft: '15px' }}>
												Total
											</HeaderCell>
										</HeaderRow>
									</Header>
									<Body>
										{tableList.map((dev, index) => (
											<>
												<Row key={index} item={dev}>
													<Cell
														onClick={() => getUser(dev)}
														pinLeft
														style={{
															borderLeft: idsShowChart.includes(dev.name)
																? '4px solid var(--bs-primary)'
																: 'none',
															borderRadius: idsShowChart.includes(dev.name)
																? '5px 0 0 0'
																: 'inherit',
														}}
														key={index}
													>
														{dev.name}
													</Cell>

													{dev[quantityLabel].map((data, index) => (
														<Cell onClick={() => handleSetIdsChart(dev.name)} key={index}>
															{data}
														</Cell>
													))}

													<Cell onClick={() => handleSetIdsChart(dev.name)} key={index}>
														{dev[totalYearLabel]}
													</Cell>
												</Row>
												{idsShowChart.includes(dev.name) ? (
													<tr key={index} className='d-flex' pinLeft style={{ gridColumn: '1/-1' }}>
														<Cell
															pinLeft
															style={{
																width: '100vw',
																boxShadow: 'rgb(218, 218, 218) 5px 5px 0px -2px',
																borderLeft: '4px solid var(--bs-primary)',
																borderRadius: '0 0 0 5px',
															}}
															key={index}
														>
															<h6 className='text-muted'>Total: {dev[totalYearLabel]}</h6>
															<Chart
																type='area'
																height='100'
																width='800'
																options={lineChartData(dev[quantityLabel]).options}
																series={lineChartData(dev[quantityLabel]).series}
															/>
														</Cell>
													</tr>
												) : null}
											</>
										))}
									</Body>
								</>
							)}
						</Table>
						<Pagination data={deferredDataTable} pagination={pagination} />
					</TableWrapper>
				</div>
			) : (
				<div
					className='d-flex justify-content-center align-items-center'
					style={{ height: '200px' }}
				>
					<Spinner />
				</div>
			)}
		</>
	);
};

export default DevsOverview;
