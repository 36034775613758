import styled from 'styled-components';

export const TableWrapper = styled.div`
	overflow: hidden;
	table{
		--data-table-library_grid-template-columns:
			max-content
			max-content
			max-content
			max-content
			max-content
			max-content
			30% !important;

	th{
		text-align: left;

		background-color: var(--bs-white);

		transition: all 0.2s ease-in;
	}
	td{
		background-color: var(--bs-white);
		cursor: pointer;
	}
  td:nth-child(n+2) {
    text-align: left;
  }
	td:hover{
		background-color: var(--bs-gray-200);
	}
	td:nth-child(1) {
		box-shadow: rgb(218, 218, 218) 5px 0px 5px -2px
  }
}`;
